import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Logo = ({ style }) => {
    const data = useStaticQuery(graphql `
    query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 78) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style = { style }
    fluid = { data.logo.childImageSharp.fluid } alt="Rodrigo de Alvarenga Logo"
    />
}

export default Logo