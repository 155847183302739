/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import "bootstrap/dist/css/bootstrap.css"
import "./layout.scss"
import image from "../images/class.png"
import { setConfig } from 'react-hot-loader';
import "@fontsource/lato/400.css"
import "@fontsource/lato/900.css"
import "../fonts/didot/medium.scss"
import "../fonts/didot/bold.scss"

setConfig({
  reloadHooks: false,
});

const Layout = ({ style, children, lang, title, link, description }) =>
  <>
    <Helmet>
      <html lang={lang} />
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={link} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      <meta property="og:url" content={link} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@roddealvarenga" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org/",
          "@type": "Person",
          "name": "Rodrigo de Alvarenga",
          "url": "https://www.rodrigodealvarenga.com",
          "image": "https://hagventures.s3.amazonaws.com/logos/logo.png",
          "sameAs": [
            "https://www.facebook.com/rodrigo.d.alvarenga",
            "https://twitter.com/roddealvarenga",
            "https://www.instagram.com/roddealvarenga/",
            "https://www.linkedin.com/in/rodrigodealvarenga/"
          ],
          "jobTitle": "CEO",
          "worksFor": {
            "@type": "Organization",
            "name": "HAG Ventures"
          }  
        }
      `}
      </script>

    </Helmet>
    <div id="wrapper">
      <main id="main" style={style}>{children}</main>
      <footer style={{ color: `#494949`, textAlign: `center` }}>
      </footer>
    </div>
  </>

export default Layout
